import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fadeInYAxis } from '../utils/animation.utils';

const Mailto = ({ email, subject = '', body = '', children }) => {
  let params = subject || body ? '?' : '';
  if (subject) params += `subject=${encodeURIComponent(subject)}`;
  if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

  return (
    <a className="btn" href={`mailto:${email}${params}`}>
      {children}
    </a>
  );
};

const Contact = () => {
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const { t } = useTranslation('common');

  const introText = React.useRef();

  React.useLayoutEffect(() => {
    fadeInYAxis(introText, '.intro', '200%');
  }, []);

  return (
    <main>
      <section className="wrapped text-center mt-200 intro" ref={introText}>
        <h2 className="mb-32">{t('contact.title')}</h2>
        <p>{t('contact.sub')}</p>
      </section>
      <section className="wrapped">
        <div className="form">
          <input
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            placeholder={t('contact.subject')}
            className="w-100"
            name="Subject"
            id="subject"
            type="text"
          />
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder={t('contact.message')}
            className="w-100"
            name="message"
            id="message"
          />
          <Mailto email={process.env.REACT_APP_MAIL_TO} subject={subject} body={message}>
            {t('contact.submit')}
          </Mailto>
        </div>
      </section>
    </main>
  );
};

export default Contact;
