import React from 'react';
import '../scss/Retro.scss';
import { useTranslation } from 'react-i18next';
import Hero from '../components/Hero';

import controlBox from '../assets/images/control-box.jpg';
import Charts from '../components/Charts';
import ContactTeaser from '../components/ContactTeaser';
import { staggerInYAxis, fadeInYAxis } from '../utils/animation.utils';

const RetroFit = () => {
  const { t } = useTranslation('common');

  const introText = React.useRef();
  const shortText = React.useRef();

  React.useLayoutEffect(() => {
    fadeInYAxis(introText, '.intro', '200%');
    fadeInYAxis(shortText, '.intro', '200%');
    staggerInYAxis('.inner', '.timeline', '200%');
  });

  const chartData = [
    {
      number: '3 Mio.',
      text: t('retro.chart-text1'),
      datasets: [
        {
          data: [100],
          backgroundColor: ['#F5F5F5'],
          borderWidth: 0,
          cutout: '90%',
        },
      ],
    },
    {
      number: '100<sup>%</sup>',
      text: t('retro.chart-text2'),
      datasets: [
        {
          data: [100],
          backgroundColor: ['#F5F5F5'],
          borderWidth: 0,
          cutout: '90%',
        },
      ],
    },
    {
      number: '100<sup>%</sup>',
      text: t('retro.chart-text3'),
      datasets: [
        {
          data: [100],
          backgroundColor: ['#F5F5F5'],
          borderWidth: 0,
          cutout: '90%',
        },
      ],
    },
  ];

  return (
    <main>
      <Hero>
        <h1 dangerouslySetInnerHTML={{ __html: t('retro.title') }}></h1>
        <h2 dangerouslySetInnerHTML={{ __html: t('retro.sub') }}></h2>
      </Hero>
      <section className="wrapped intro">
        <div ref={introText}>
          <h2 className="mb-18 uppercase" dangerouslySetInnerHTML={{ __html: t('retro.intro.title') }}></h2>
          <p className="justify">{t('retro.intro.text')}</p>
        </div>
      </section>
      <section className="timeline">
        <div className="grid">
          <div className="inner">
            <h2>01</h2>
            <p>{t('retro.timeline.text1')}</p>
            <div className="time-line"></div>
          </div>
          <div className="inner">
            <h2>02</h2>
            <p>{t('retro.timeline.text2')}</p>
            <div className="time-line"></div>
          </div>
          <div className="inner">
            <h2>03</h2>
            <p>{t('retro.timeline.text3')}</p>
            <div className="time-line"></div>
          </div>
          <div className="inner">
            <h2>04</h2>
            <p>{t('retro.timeline.text4')}</p>
          </div>
        </div>
      </section>
      <section className="wrapped">
        <p className="justify" dangerouslySetInnerHTML={{ __html: t('retro.short-text') }} ref={shortText}></p>
      </section>
      <section className="wrapped">
        <img className="w-100 mood-img" src={controlBox} alt="controlBox" />
      </section>
      <Charts data={chartData} />
      <ContactTeaser type={'small'} />
    </main>
  );
};

export default RetroFit;
