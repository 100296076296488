import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../scss/JobTeaser.scss';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';

import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

import axios from 'axios';

const JobTeaser = () => {
  const [data, setData] = useState(null);
  const { t } = useTranslation('common');

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/job-teasers?populate[job_website][fields][0]=slug`)
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => console.error(error));
  }, []);

  return data ? (
    <section className="job-teaser">
      <div className="wrapped text-center mb-84">
        <h2 className="mb-18">{t('home.jobs.title')}</h2>
        <p>{t('home.jobs.sub')}</p>
      </div>
      <Swiper
	    modules={[Navigation]}
        loop={false}
        slidesPerView={1}
        spaceBetween={10}
		navigation={true}
        breakpoints={{
          576: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
        }}>
        {data.map((item) => {
          return (
            <SwiperSlide key={item.id}>
              <div className="job-card text-center">
                <h3>{i18n.language === 'de' ? item.attributes.titleDe : item.attributes.titleEn}</h3>
                <p>{i18n.language === 'de' ? item.attributes.descriptionDe : item.attributes.descriptionEn}</p>

                {item.attributes.job_website?.data?.attributes.slug ? (
                  <Link to={`/jobs/${item.attributes.job_website?.data?.attributes.slug}`} className="btn">
                    {t('home.jobs.btn')}
                  </Link>
                ) : null}
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  ) : null;
};

export default JobTeaser;
