import './App.scss';
import React, { Suspense, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Nav from './components/Nav';
import Home from './pages/Home';
import Footer from './components/Footer';
import RetroFit from './pages/RetroFit';
import Team from './pages/Team';
import Industrie from './pages/Industrie';
import Automation from './pages/Automation';
import Contact from './pages/Contact';
import ScrollToTop from './components/ScrollToTop';
import Imprint from './pages/Imprint';
import Privacy from './pages/Privacy';
import Jobs from './pages/Jobs';
import PageNotFound from './pages/PageNotFound';
import Career from './pages/Career';

function App() {
  const [finishedScrolling, setFinishedScrolling] = useState(false)

  return (
    <Suspense fallback="loading">
      <div className="App">
        <Nav />
        <ScrollToTop className="content-wrap" finished={(event) => setFinishedScrolling(event)}>
          {finishedScrolling ? (
            <Routes>
              <Route index element={<Home />} />
              <Route path={'/retroFit'} element={<RetroFit />} />
              <Route path={'/team'} element={<Team />} />
              <Route path={'/career'} element={<Career />} />
              <Route path={'/industrie4.0'} element={<Industrie />} />
              <Route path={'/automation'} element={<Automation />} />
              <Route path={'/contact'} element={<Contact />} />
              <Route path={'/imprint'} element={<Imprint />} />
              <Route path={'/privacy'} element={<Privacy />} />
              <Route path={'/jobs/:slug'} element={<Jobs />} />

			  <Route path="*" element={<PageNotFound />} />
            </Routes>
          ) : null}
        </ScrollToTop>
        <Footer />
      </div>
    </Suspense>
  );
}

export default App;
