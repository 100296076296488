import { useTranslation } from 'react-i18next';
import React from 'react';

const PageNotFound = () => {
  const { t } = useTranslation('common');

  return (
    <main>
      <section className="wrapped">
        <h2 style={{ fontSize: '100px' }} className="mt-100">
          404
        </h2>
        <h3 style={{ fontSize: '50px' }}>{t('PageNotFound')}</h3>
      </section>
    </main>
  );
};

export default PageNotFound;
