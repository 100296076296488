import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import de from "./translations/de.json";
import en from "./translations/en.json";
i18n.use(initReactI18next).init({
  debug: true,

  interpolation: { escapeValue: false }, // React already does escaping
  lng: "de", // language to use
  resources: {
    en: { common: en },
    de: { common: de },
  },
});
export default i18n;
