import React from 'react';
import '../scss/FullWidthTeaser.scss';
import { fadeIn, fadeInXAxis } from '../utils/animation.utils';

const FullWidthTeaser = (props) => {
  const { children, img, borderBottom, big } = props;

  const textRef = React.useRef();
  const overlayRef = React.useRef();
  const imgRef = React.useRef();

  React.useLayoutEffect(() => {
    fadeInXAxis(overlayRef, '-100%', 0, 'top', 'center');
    fadeInXAxis(textRef, '-200%', 0.75, 'top', 'bottom');
    fadeIn(imgRef, overlayRef, 0.75, 'top', 'center');
  });

  return (
    <section className={`full-width-teaser ${big ? 'big' : ''}`}>
      <div className="text" ref={textRef}>
        {children}
      </div>
      <div className="overlay" ref={overlayRef}></div>
      <img src={img} alt={img} className="bg-img" ref={imgRef} />
      {borderBottom ? <div className="border-bottom"></div> : null}
    </section>
  );
};

export default FullWidthTeaser;
