import React, { useEffect, useState, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import i18n from '../i18n';
import PageNotFound from './PageNotFound';
import { fadeInXAxis, fadeInYAxis } from '../utils/animation.utils';
import logo from '../assets/icons/EW-Tec-Logo.svg';

const Jobs = () => {
  const [data, setData] = useState(null);
  const [pageNotFound, setPageNotFound] = useState(false);
  const params = useParams();
  const { t } = useTranslation('common');

  const titleRef = useRef();
  const awaitsRef = useRef();
  const expectRef = useRef();
  const offerRef = useRef();
  const whyRef = useRef();
  const contactRef = useRef();

  const checkLanguage = (de, en) => {
    return i18n.language === 'de' ? de : en;
  };

  const loadAnimations = () => {
    fadeInYAxis(titleRef, '.intro-job', '200%');
    fadeInXAxis(awaitsRef, '-200%');
    fadeInXAxis(expectRef, '200%');
    fadeInXAxis(offerRef, '-200%');
    fadeInXAxis(whyRef, '200%');
    fadeInYAxis(contactRef, '.contact', '50%');
  };

  var jobStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'JobPosting',
    title: '',
    description: '',
    image: '',
    datePosted: '',
    hiringOrganization: {
      '@type': 'Organization',
      name: 'EW-Tec',
      sameAs: 'https://ew-tec.com/',
      logo: logo,
    },
    jobLocation: {
      '@type': 'Place',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'Helmholtzstraße 8',
        addressLocality: 'Viersen',
        postalCode: '41747',
        'addressCountry': 'DE',
      },
    },
    jobLocationType: '',
  };

  var jobLocationViersen = {
    '@type': 'Place',
    address: {
      '@type': 'PostalAddress',
      streetAddress: '',
      addressLocality: 'Viersen',
      postalCode: '41747',
      'addressCountry': 'DE',
    },
  };

  const fillJobStructuredData = (data) => {
    jobStructuredData.title = data?.attributes.jobTitleDe;
    jobStructuredData.description = data?.attributes.awaitsTextDe;
    jobStructuredData.datePosted = data?.attributes.publishedAt;
    var location = data?.attributes.locationDe;

    // show correct job location
    if (location.includes('Viersen')) {
      jobStructuredData.jobLocation = jobLocationViersen;
    }

	if (location.includes("Hybrid"))
	{
		jobStructuredData.jobLocationType = "TELECOMMUTE"
	}
  };

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/job-websites/${params.slug}`).then((response) => {
      setData(response.data.data);
      setPageNotFound(false);
      loadAnimations();
    });
    // .catch(() => setPageNotFound(true));
  }, [params]);

  if (data) {
    fillJobStructuredData(data);
    return (
      <main className="job-page">
        <script type="application/ld+json">{JSON.stringify(jobStructuredData)}</script>
        <section className="wrapped text-center mt-200 intro-job">
          <Link to="/career" className="btn btn-small mb-54 btn-light">
            <p className="text-center">{t('Back')}</p>
          </Link>
          <div ref={titleRef}>
            <h2 className="mb-18 uppercase">{checkLanguage(data?.attributes.titleDe, data?.attributes.titleEn)}</h2>
            <p>{checkLanguage(data?.attributes.locationDe, data?.attributes.locationEn)}</p>
            <h1 className="uppercase">{checkLanguage(data?.attributes.jobTitleDe, data?.attributes.JobTitleEn)}</h1>
          </div>
        </section>
        <section ref={awaitsRef} className="wrapped">
          <h2 className="mb-32 uppercase">{t('job.awaits')}</h2>
          <ReactMarkdown>{checkLanguage(data?.attributes.awaitsTextDe, data?.attributes.awaitsTextEn)}</ReactMarkdown>
        </section>
        <section ref={expectRef} className="wrapped">
          <h2 className="mb-32 uppercase">{t('job.expect')}</h2>
          <ReactMarkdown>{checkLanguage(data?.attributes.expectTextDe, data?.attributes.expectTextEn)}</ReactMarkdown>
        </section>
        <section ref={offerRef} className="wrapped">
          <h2 className="mb-32 uppercase">{t('job.offer')}</h2>
          <ReactMarkdown>{checkLanguage(data?.attributes.offerTextDe, data?.attributes.offerTextEn)}</ReactMarkdown>
        </section>
        <section ref={whyRef} className="wrapped">
          <h2 className="mb-32 uppercase" dangerouslySetInnerHTML={{ __html: t('job.why') }}></h2>
          <ReactMarkdown>{checkLanguage(data?.attributes.howWeDoItDE, data?.attributes.howWeDoItEn)}</ReactMarkdown>
        </section>
        <section ref={contactRef} className="wrapped contact text-center">
          <h2 className="mb-32 uppercase" dangerouslySetInnerHTML={{ __html: t('job.contact.title') }}></h2>
          <p className="mb-32">
            EW-Tec Industrieservice &amp; Anlagentechnik GmbH
            <br />
            Helmholtzstraße 8
            <br />
            41747 Viersen
          </p>
          <p>Ansprechpartner*in: {data?.attributes.contactPerson}</p>
          <p>
            E-Mail:{' '}
            <a className="text-underline" href="mailto:info@ew-tec.com">
              info@ew-tec.com
            </a>
          </p>
          <p> Tel.: +49 2162 36170-127</p>
          <Link to="/career" className="btn btn-small mt-200">
            <p className="text-center">{t('Back')}</p>
          </Link>
        </section>
      </main>
    );
  } else if (pageNotFound) {
    return <PageNotFound />;
  } else {
    return null;
  }
};

export default Jobs;
