import React from 'react';
import '../scss/Footer.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import worldFooter from '../assets/images/worldmap.svg';

const Footer = () => {
  const { t } = useTranslation('common');

  return (
    <footer>
      <div className="content wrapped">
        <div>
          <h3>{t('Contact')}</h3>
          <p>
            EW-Tec Industrieservice & Anlagentechnik GmbH
            <br />
            Helmholtzstraße 8
            <br />
            D-41747 Viersen
            <br />
            info@ew-tec.com
            <br /> Tel. +49 2162 36170-100
          </p>
        </div>
        <div>
          <h3>{t('More')}</h3>
          <div className="links">
            <Link to="/imprint">
              <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8.36372 6.99747L2.67004 12.6911C2.39544 12.9657 1.95025 12.9657 1.67568 12.6911L1.01161 12.027C0.737476 11.7529 0.736949 11.3086 1.01044 11.0338L5.52277 6.50028L1.01044 1.96673C0.736949 1.69196 0.737476 1.24767 1.01161 0.973538L1.67568 0.309465C1.95028 0.0348657 2.39547 0.0348657 2.67004 0.309465L8.36369 6.00311C8.63829 6.27768 8.63829 6.72287 8.36372 6.99747Z"
                  fill="#FAFAFF"
                />
              </svg>
              {t('Imprint')}
            </Link>
            <Link to="privacy">
              <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8.36372 6.99747L2.67004 12.6911C2.39544 12.9657 1.95025 12.9657 1.67568 12.6911L1.01161 12.027C0.737476 11.7529 0.736949 11.3086 1.01044 11.0338L5.52277 6.50028L1.01044 1.96673C0.736949 1.69196 0.737476 1.24767 1.01161 0.973538L1.67568 0.309465C1.95028 0.0348657 2.39547 0.0348657 2.67004 0.309465L8.36369 6.00311C8.63829 6.27768 8.63829 6.72287 8.36372 6.99747Z"
                  fill="#FAFAFF"
                />
              </svg>
              {t('Privacy')}
            </Link>
          </div>
        </div>
        <div>
          <h3>{t('OurProjects')}</h3>
          <img src={worldFooter} alt="ourprojects" />
        </div>
      </div>
      <div className="bottom-line">
        <p className="wrapped">Copyright © 2024. All right reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
