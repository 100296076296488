import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Hero from '../components/Hero';
import FullWidthTeaser from '../components/FullWidthTeaser';
import WorldMap from '../components/WorldMap';
import ContactTeaser from '../components/ContactTeaser';
import SliderLarge from '../components/SliderLarge';
import JobTeaser from '../components/JobTeaser';

import Service from "../assets/icons/service.svg";
import fwTeaser from "../assets/images/home-fwt.jpg";
import sliderChem from "../assets/images/slider-chemistry.jpg";
import sliderKent from "../assets/images/slider-kentucky.jpg";
import sliderLevel from "../assets/images/slider-leveling.jpg";
import sliderOptics from "../assets/images/slider-optics.jpg";
import sliderStart from "../assets/images/slider-start.jpg";
import siemens from "../assets/images/logo-siemens.png";
import rittal from "../assets/images/logo-rittal.png";
import wachendorff from "../assets/images/logo-wachendorff.png";
import wuerth from "../assets/images/logo-wuerth.png";
import eplan from "../assets/images/logo-eplan.png";
import phoenix from "../assets/images/logo-phoenix.png";
import rockwell from "../assets/images/logo-rockwell.png";
import SliderLogos from "../components/SliderLogos";
import { fadeInXAxis, fadeInYAxis, staggerInYAxis } from '../utils/animation.utils';

const Home = () => {
  const { t } = useTranslation('common');
  const leftRef = React.useRef();
  const rightRef = React.useRef();
  const lineRef = React.useRef();
  const sliderText = React.useRef();

  React.useLayoutEffect(() => {
    fadeInXAxis(leftRef, '-200%');
    fadeInXAxis(rightRef, '200%');
    fadeInYAxis(lineRef, '.home-text', '200%');
    fadeInYAxis(sliderText, '.slider-large', '200%', 0.4);
    staggerInYAxis('.service-card', '.service-squares', '200%');
  });

  const partnerLogos = [
    siemens, rittal, eplan, phoenix, wachendorff, wuerth, rockwell
  ]

  const slideData = [
    {
      title: t('home.slider.slide1.title'),
      img: sliderKent,
      text: t('home.slider.slide1.text'),
    },
    {
      title: t('home.slider.slide2.title'),
      img: sliderOptics,
      text: t('home.slider.slide2.text'),
    },
    {
      title: t('home.slider.slide3.title'),
      img: sliderLevel,
      text: t('home.slider.slide3.text'),
    },
    {
      title: t('home.slider.slide4.title'),
      img: sliderStart,
      text: t('home.slider.slide4.text'),
    },
    {
      title: t('home.slider.slide5.title'),
      img: sliderChem,
      text: t('home.slider.slide5.text'),
    },
  ];

  return (
    <main className="home">
      <Hero>
        <h1 dangerouslySetInnerHTML={{ __html: t('home.title') }}></h1>
        <h2>{t('home.sub')}</h2>
      </Hero>
      <section className="wrapped flex home-text justify-center">
        <div className="text-right" ref={leftRef}>
          <h2 className="mb-18" dangerouslySetInnerHTML={{ __html: t('home.intro.title') }}></h2>
          <h3 dangerouslySetInnerHTML={{ __html: t('home.intro.sub') }}></h3>
        </div>
        <div className="line" ref={lineRef}></div>
        <p className="w-50 w-mb-100 justify" ref={rightRef}>
          {t('home.intro.text')}
        </p>
      </section>
      <section className="wrapped service-squares">
        <Link to="/automation" className="service-card">
          <img src={Service} alt="service" />
          <p className="text-center">Industrial Automation</p>
        </Link>
        <Link to="/industrie4.0" className="service-card">
          <img src={Service} alt="service" />
          <p className="text-center">Industrie 4.0</p>
        </Link>
        <Link to="/retroFit" className="service-card">
          <img src={Service} alt="service" />
          <p className="text-center">Retro Fit</p>
        </Link>
      </section>
      <FullWidthTeaser img={fwTeaser} borderBottom={true}>
        <h2 className="uppercase mb-18">{t('home.fw-teaser.title')}</h2>
        <h3 className="mb-54">{t('home.fw-teaser.sub')}</h3>
        <p className="justify">{t('home.fw-teaser.text-list')}</p>
        <ul className="mb-32">
          <li>{t('home.fw-teaser.list1')}</li>
          <li>{t('home.fw-teaser.list2')}</li>
          <li>{t('home.fw-teaser.list3')}</li>
          <li>{t('home.fw-teaser.list4')}</li>
          <li>{t('home.fw-teaser.list5')}</li>
          <li>{t('home.fw-teaser.list6')}</li>
        </ul>
        <p className="justify" dangerouslySetInnerHTML={{ __html: t('home.fw-teaser.text-end') }}></p>
      </FullWidthTeaser>
      <SliderLogos logos={partnerLogos}>
        <h2 className="wrapped mb-32">{t('home.partner')}</h2>
      </SliderLogos>
      <WorldMap />
      <ContactTeaser type={'big'} />
      <SliderLarge data={slideData}>
        <div className="wrapped" ref={sliderText}>
          <h2 className="mb-18">{t('home.slider.title')}</h2>
          <h3 className="mb-54">{t('home.slider.sub')}</h3>
          <p className="mb-84 justify">{t('home.slider.text')}</p>
        </div>
        <h2 className="wrapped mb-32">{t("home.slider.headline")}</h2>
      </SliderLarge>
      <JobTeaser />
    </main>
  );
};

export default Home;
