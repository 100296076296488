import React from 'react';
import '../scss/WorldMap.scss';
import { useTranslation } from 'react-i18next';
import Charts from './Charts';
import worldmap from '../assets/images/worldmap.svg';
import { fadeInYAxis } from '../utils/animation.utils';

const WorldMap = () => {
  const { t } = useTranslation('common');

  const textRef = React.useRef();
  const boldText = React.useRef();

  React.useLayoutEffect(() => {
    fadeInYAxis(textRef, '.world-map', '200%');
    fadeInYAxis(boldText, '.bold-text', '200%');
  });

  const chartData = [
    {
      number: 291,
      text: t('home.world-map.chart-text1'),
      datasets: [
        {
          data: [100],
          backgroundColor: ['#F5F5F5'],
          borderWidth: 0,
          cutout: '90%',
        },
      ],
    },
    {
      number: '36,4<sup>%</sup>',
      text: t('home.world-map.chart-text2'),
      datasets: [
        {
          data: [36.4, 63.6],
          backgroundColor: ['#F5F5F5', '#8BA2C1'],
          borderWidth: 0,
          cutout: '90%',
        },
      ],
    },
    {
      number: '63,6<sup>%</sup>',
      text: t('home.world-map.chart-text3'),
      datasets: [
        {
          data: [63.6, 36.4],
          backgroundColor: ['#F5F5F5', '#8BA2C1'],
          borderWidth: 0,
          cutout: '90%',
        },
      ],
    },
  ];

  return (
    <React.Fragment>
      <section className="wrapped world-map">
        <div ref={textRef}>
          <h2 className="mb-18">{t('home.world-map.title')}</h2>
          <h3 className="mb-54">{t('home.world-map.sub')}</h3>
          <p className="justify">{t('home.world-map.text')}</p>
        </div>
        <img src={worldmap} alt="worldmap" />
        <p className="bold text-center bold-text" ref={boldText}>
          {t('home.world-map.bold-text')}
        </p>
      </section>
      <Charts data={chartData} />
    </React.Fragment>
  );
};

export default WorldMap;
