import React from "react";
import "../scss/SliderLarge.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from 'swiper';
import "swiper/css";
import "swiper/css/navigation";

const SliderLarge = (props) => {
  const { data, children } = props;
  return (
    <section className="slider-large">
      {children}
      <div className="slider-wrapper">
        <Swiper
          modules={[Navigation]}
          slidesPerView={1}
          spaceBetween={15}
          loop={true}
          navigation
          breakpoints={{
            576: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
            1000: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            1440: {
              slidesPerView: 2.2,
              spaceBetween: 30,
            },
          }}>
          {data.map((slide, index) => {
            return (
              <SwiperSlide key={index}>
                <div className="slider-inner" style={{ backgroundImage: `url(${slide.img})` }}>
                  <div className="slider-text">
                    <h2 className="mb-32">{slide.title}</h2>
                    <p dangerouslySetInnerHTML={{ __html: slide.text }} />
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </section>
  );
};

export default SliderLarge;
