import React from 'react';
import Hero from '../components/Hero';
import JobTeaser from '../components/JobTeaser';
import { useTranslation } from 'react-i18next';

const Career = () => {
  const { t } = useTranslation('common');

  return (
    <main className="career">
      <Hero>
        <h1 dangerouslySetInnerHTML={{ __html: t('career.title') }}></h1>
        <h2>{t('career.sub')}</h2>
      </Hero>
      <JobTeaser />
    </main>
  );
};

export default Career;
