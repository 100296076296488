import "../scss/Hero.scss";
import React from "react";
import hero from "../assets/images/hero-2.png";
import { fadeInXAxis } from '../utils/animation.utils';

const Hero = (props) => {
  const { children } = props;
  const introText = React.useRef();

  React.useLayoutEffect(() => {
    fadeInXAxis(introText, '200%');
  });

  return (
    <section className="hero">
      <div className="hero-text" ref={introText}>{children}</div>
      <div className="overlay"></div>
      <img className="bg-img" src={hero} alt="heroimg" />
    </section>
  );
};

export default Hero;
